import axios from "axios";
import Md5 from "@/utils/md5";
import {message} from 'ant-design-vue'
import {useUserStoreWithOut} from "@/store/user.ts";
import {router} from "@/router";
// 跨域请求，允许保存cookie
// axios.defaults.withCredentials = true;

const axiosInstance = axios.create({
    baseURL: (window as any)['baseURL'] || '/base',
    timeout: 1000 * 10,
    headers: {'Content-Type': 'application/x-www-form-urlencoded'}
});

axiosInstance.interceptors.request.use(async (config) => {
    const {getToken} = useUserStoreWithOut()
    if (getToken) {
        config.headers['ustoken'] = getToken
    }
    return config
}, (err) => {
    return Promise.reject(err);
},)
// 添加一个响应拦截器
axiosInstance.interceptors.response.use(
    (res) => {
        if (res.data.status != 1) {
            if (res.data.status == -2) {
                const {quit} = useUserStoreWithOut();
                quit()
                router.push('/login')
            } else {
                message.error(res.data.msg)
                return Promise.reject(res.data);
            }
        }
        return res
    },
    async (err) => {
        // const {setToken} = useUserStoreWithOut()
        // if (err.response.status == 401) {
        //     const {data} = await getAuth()
        //     setToken(data.token)
        //     return await axiosInstance.request(err.config)
        // }
        return Promise.reject(err);
    },
)

export function get(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
        axiosInstance.get(url, {params}).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}

function toQueryString(obj: any) {
    let arr = [];
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            arr.push(`${key}=${obj[key]}`);
        }
    }
    return arr.join('&');
}

export function post(url: string, params?: any): Promise<any> {
    return new Promise((resolve, reject) => {
        const sortedKeys = Object.keys(params).sort();
        const sortedObject: any = {};
        sortedKeys.forEach(key => {
            if (params[key] != undefined) {
                if (params[key] != "") {
                    sortedObject[key] = params[key];
                }
            }
        });
        // console.log(1122, toQueryString(sortedObject) + 'X5A1C53E3-067DAE70-3AE1C5EE')
        const sign: string = Md5(toQueryString(sortedObject) + 'X5A1C53E3-067DAE70-3AE1C5EE')
        const param = Object.assign({}, sortedObject, {sign})
        axiosInstance.post(url, param,).then(res => {
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
    })
}
